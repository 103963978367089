
















































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import rules from "@/components/account/rules";
import stepFielfdMixin from "../stepFielfdMixin";
import { mapActions, mapGetters, mapState } from "vuex";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";

export default Vue.extend({
  components: { MaDatePicker },
  name: "patient-information",
  mixins: [stepFielfdMixin],
  data() {
    return {
      lastExamDateMenu: false,
      birthDateMenu: false,
      rules,
      gender: 0,
      loadingCities: false,
      loading: false,
      stateCities: [],
      state: 0,
      mailingCity: "",
      fields: {
        abortion: false, //
        adrenalGlandTumor: false, //
        allergicClose: false, //
        birthDate: "", // 2021-07-26T01:17:29.898Z
        bleedingDisorders: false, //
        celebralPalsy: false, //
        city: "", //
        contactNumber: "", // 17868027461
        controledSubtances: false, //
        country: "", //
        doctorName: "", //
        doctorPhone: "", // 17868027461
        email: "", //
        emergencyContactNumber: "", // 17868027461
        firstName: "", //
        fullname: "",
        goodHealfh: false, //
        height: "", //
        isMale: false, //
        jointDeformities: false, //
        lastExamDate: "", // 2021-07-26T01:17:29.898Z
        lastName: "", //
        midleName: "", //
        nameOfSurgery: "", //
        ocupation: "", //
        previusSurgical: "",
        relationship: "", //
        rheumatoidArthritis: false, //
        sickleCellDisease: false, //
        maritalStatus: "Married", //
        state: "", //
        streetAddres: "", //
        streetAddresLine2: "",
        surgicalOperations: false, //
        systemicLupus: false, //
        takingMedication: false, //
        thromboticDisorders: false, //
        underMedicalTreatment: false, //
        useAlcoholic: false, //
        useTabacoOrNicotines: false, //
        weight: "", //
        weiringContactLenses: false, //
        zipCode: "", //
      },
    };
  },
  computed: {
    ...mapGetters(["getpatientN"]),
    ...mapState("crmMedicFormModule", ["states", "loadingStates"]),
  },
  mounted() {
    this.actListStates();
    setTimeout(() => {
      this.state = 1;
    }, 3000);
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actListStates", "actGetCityByState"]),
  },
});
